<template>
  <div>
    <div class="new">{{ $t('transaction.zxcj') }}</div>
    <div class="box-left-list">
      <div>{{ $t('transaction.jg') }}(USDT)</div>
      <div>{{ $t('transaction.sl') }}({{moneyType}})</div>
      <div style="text-align: right;">{{ $t('transaction.sj') }}</div>
    </div>
    <div class="line"></div>
    <div class="box-left-list ul-list" v-for="item2 in getData2" :key="item2.id">
      <div style="color: rgba(255, 255, 255, 0.8);">{{item2.price}}</div>
      <div style="color: rgba(255, 255, 255, 0.8);">{{item2.qty}}</div>
      <div style="text-align: right;color: rgba(255, 255, 255, 0.8);">{{item2.dateStr}}</div>
    </div>
  </div>
</template>

<script>
import { trades} from "@/api/bnb";
import tools from "@/utils/tools";

export default {
  name: "latestTransaction",
  components: {},
  props: {},
  data() {
    return {
      getData2: [],
      timeServe: null,
      moneyType:'ETH',
    }
  },
  watch: {},
  mounted() {
    if (this.timeServe) {
      clearInterval(this.timeServe)
    }
    this.timeServe = setInterval(() => {
      // this.trades()
    }, 5000)
    this.trades()
  },
  methods: {
    setPrice(){

    },
    trades(){
      trades({'limit':10}).then(res=>{
        if(res.code===1){
          this.getData2=[]
          res.data.forEach((item)=>{
            if(item.time){
              item.price=tools.setPrice(item.price)
              item.qty=tools.setPrice(item.qty)
              item.dateStr=this.timeformat(item.time)
              this.getData2.push(item)
            }
          })
        }
      })
    },
    timeformat(end_time) {
      if(!end_time){
        return  '';
      }
      console.log(end_time)
      let dt = new Date(end_time );
      // var y = dt.getFullYear();
      // var m = (dt.getMonth() + 1).toString().padStart(2, "0");
      // var d = dt.getDate().toString().padStart(2, "0");
      let h = dt.getHours().toString().padStart(2, "0");
      let f = dt.getMinutes().toString().padStart(2, "0");
      let k = dt.getSeconds().toString().padStart(2, "0");
      // return `${y}-${m}-${d} ${h}:${f}:${k}`;
      return `${h}:${f}:${k}`;
    },
  }
}
</script>

<style scoped lang="scss">
.new {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  border-top: 1px solid #29343D;
  border-bottom: 1px solid #29343D;
  padding: 10px;
}

.box-left-list {
  display: flex;
  align-items: center;
  padding: 6px 20px;
}

.line {
  height: 1px;
  width: 100%;
  background: #29343D;
}

.ul-list {
  padding: 10px 20px;

}

.box-left-list div {
  color: rgba(255, 255, 255, 0.6);
  width: 33.33%;
  font-size: 10px;
  // overflow: hidden;
}

</style>
