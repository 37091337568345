import { Message } from 'element-ui'
// import { getRoute } from '@/utils/auth'

let tools = {}

/**
 * 大小判断
 * @param a
 * @param b
 * @returns {number}
 */
tools.sortNumber = function (a, b) {
  return a - b;
}

tools.error = function (msg) {
  Message({
    message: msg,
    type: 'error',
    duration: 5 * 1000
  })
}

tools.success = function (msg) {
  Message({
    message: msg,
    type: 'success',
    duration: 5 * 1000
  })

}

tools.setPrice = function (setPrice) {
  return (setPrice*1).toFixed(setPrice>1000?2:6)
}

tools.image2Base64 = function (img) {
  let canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  let dataURL = canvas.toDataURL("image/png");
  console.log(dataURL)
  return dataURL;
}






export default tools
