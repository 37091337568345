import request from '@/utils/request'

const url = 'api/bnb/'

//获取阿里云权限 get
export const getKlines = (data) => request(url+'klines','get',data,false)
export const getDepth = (data) => request(url+'depth','get',data,false)
export const get24Hr = (data) => request(url+'24hr','get',data,false)
export const trades = (data) => request(url+'trades','get',data,false)

