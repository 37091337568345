<template>
  <div>
    <div class="new">{{title}}</div>
    <div class="box-left-list">
      <div>{{ $t('transaction.jg') }}(USDT)</div>
      <div>{{ $t('transaction.sl') }}({{numType}})</div>
    </div>
    <div class="line"></div>
    <div class="depth-main">
      <div class="depth-main-item">
        <div class="dmi-cell" v-for="(item, index) in data.bids" :key="item.id">
          <div class="dmi-cell-bg"
               :style="{ 'width': `${item[2]}%`, 'backgroundColor': 'rgba(255, 107, 101, 0.1)' }"></div>
          <div class="dmi-cell-item">
           <div class="dmi-cell-index">{{(item[0]*1) > 1000?(item[0]*1).toFixed(2):(item[0]*1).toFixed(6)||'--'}}</div>
           <div class="dmi-cell-num">{{(item[1]*1) > 1000?(item[1]*1).toFixed(2):(item[1]*1).toFixed(6)||'--'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="new new-text">{{max||"--"}}</div>
    <div class="depth-main">
      <div class="depth-main-item">
        <div class="dmi-cell" v-for="(item, index) in data.asks" :key="item.id">
          <div class="dmi-cell-bg"
               :style="{ 'width': `${item[2]}%`, 'backgroundColor': 'rgba(67, 207, 124, 0.1)' }">
          </div>
          <div class="dmi-cell-item">
            <div class="dmi-cell-index">{{(item[0]*1) > 1000?(item[0]*1).toFixed(2):(item[0]*1).toFixed(6)||'--'}}</div>
            <div class="dmi-cell-num">{{(item[1]*1) > 1000?(item[1]*1).toFixed(2):(item[1]*1).toFixed(6)||'--'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        numType:'',
        buyList: [],
        max:0,
        title:'',
        data:{},
      }
    },
    mounted() {
      document.addEventListener("newCoinDepth",(e)=>{
        if(e.detail){
          this.getList(e.detail,this.numType);
          this.$parent.setKData(e.detail)
        }
      })
    },
    methods: {
      getList(data,numType){
        this.numType = numType
        this.title = this.$t('transaction.pk')
        this.data = data
        this.setWidth(data.asks)
      },
      setWidth(list){
        if(list.length>0){
          let arr = [];
          list.forEach((v)=>{
            arr.push(v[0] * 1)
          })
          this.max = Math.max(...arr)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .new {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    border-top: 1px solid #29343D;
    border-bottom: 1px solid #29343D;
    padding: 10px;
  }
  .new-text{
    text-align: center;font-size: 22px;
  }
  .box-left-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
    div {
      color: rgba(255, 255, 255, 0.6);
      width: 33.33%;
      font-size: 10px;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background: #29343D;
  }
  .depth-main {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    .depth-main-item {
      flex: 1;
      .dmi-cell {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        height: 30px;
        .dmi-cell-bg {
          width: 0;
          height: 100%;
        }
        .dmi-cell-item {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          .dmi-cell-index {
            width: 33.33%;
            font-size: 12px;
            color: #FF6B65;
          }
          .dmi-cell-num {
            margin: 0 10px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
            width: 33.33%;
          }

          .dmi-cell-price {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
            width: 33.33%;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
    }
  }


</style>
