<template>
  <div>
    <div class="box-main-head">
      <div class="box-main-select">
        <el-select v-model="name" value-key="id" @change="changeLang($event)">
          <el-option v-for="item2 in popList" :key="item2.id" :label="item2.coin_name + '/USDT'" :value="item2">
          </el-option>
        </el-select>
      </div>
      <div class="box-main-nav">
        <div class="whte">{{ coin_price }}
          <span class="red" v-if="change_num < 0" style="margin-left:16px;">{{ change_num }}</span>
          <span class="green" v-else style="margin-left:16px;">{{ change_num }}</span>
        </div>
        <div>${{ coin_price }}</div>
      </div>
      <div class="box-main-nav">
        <div>24h{{ $t('transaction.zgj') }}</div>
        <div class="whte">{{ fomatFloat(txData.highPrice, 2) || 0 }}</div>
      </div>
      <div class="box-main-nav">
        <div>24h{{ $t('transaction.zdj') }}</div>
        <div class="whte">{{ fomatFloat(txData.lowPrice, 2) || 0 }}</div>
      </div>
      <div class="box-main-nav">
        <div>24h{{ $t('transaction.kpj') }}</div>
        <div class="whte">{{ fomatFloat(txData.openPrice, 2) || 0 }}</div>
      </div>
      <div class="box-main-nav">
        <div>24h{{ $t('transaction.cje') }}</div>
        <div class="whte">{{ fomatFloat(txData.volume, 2) || 0 }}</div>
      </div>
      <!-- <div class="box-main-nav">
        <div>24h{{ $t('transaction.cje') }}(USDT)</div>
        <div class="whte">{{ fomatFloat(txData.quoteVolume, 2) || 0 }}</div>
      </div> -->
    </div>
    <div class="box-main-line"></div>
    <div class="box-main-changeNav">
      <div :class="[changeNavIndex == '1m' ? 'changeNav-active' : '']" @click="changeNav('1m')">1{{
          $t('transaction.fz')
        }}
      </div>
      <div :class="[changeNavIndex == '5m' ? 'changeNav-active' : '']" @click="changeNav('5m')">5{{
          $t('transaction.fz')
        }}
      </div>
      <div :class="[changeNavIndex =='15m' ? 'changeNav-active' : '']" @click="changeNav('15m')">15{{
          $t('transaction.fz')
        }}
      </div>
      <div :class="[changeNavIndex == '30m'? 'changeNav-active' : '']" @click="changeNav('30m')">30{{
          $t('transaction.fz')
        }}
      </div>
      <div :class="[changeNavIndex == '1h' ? 'changeNav-active' : '']" @click="changeNav('1h')">1{{
          $t('transaction.xs')
        }}
      </div>
      <div :class="[changeNavIndex == '4h' ? 'changeNav-active' : '']" @click="changeNav('4h')">4{{
          $t('transaction.xs')
        }}
      </div>
      <div :class="[changeNavIndex == '8h' ? 'changeNav-active' : '']" @click="changeNav('8h')">8{{
          $t('transaction.xs')
        }}
      </div>
      <div :class="[changeNavIndex == '1d' ? 'changeNav-active' : '']" @click="changeNav('1d')">1{{
          $t('transaction.ri')
        }}
      </div>
      <div :class="[changeNavIndex =='1w'? 'changeNav-active' : '']" @click="changeNav('1w')">1{{
          $t('transaction.z')
        }}
      </div>
      <div :class="[changeNavIndex == '1M' ? 'changeNav-active' : '']" @click="changeNav('1M')">1{{
          $t('transaction.yue')
        }}
      </div>
    </div>
    <div id="main" class="charts"></div>
    <div class="echarts-line"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {get24Hr, getKlines} from "@/api/bnb";
import {getIconPriceList} from "@/api/deal";
let myChart;
export default {
  name: "klines",
  components: {},
  props: {},
  data() {
    return {
      popList: [],
      changeNavIndex: '1m',
      myChart: '',
      id: 0,//币种id
      txData: {},
      name: '',
      coin_price: '',
      change_num: ''

    }
  },
  watch: {},
  mounted() {
    myChart = echarts.init(document.getElementById('main'));
    this.getCredInfo()
  },
  methods: {
    getCredInfo(){
      this.draw()
      this.getPopList()
      this.getTxData()
    },
    changeNav(id) {
      this.changeNavIndex = id
      this.getKline(id, this.id)
    },
    changeLang(e) {
      this.$emit('changeEchartsType',e)
      this.getKline(this.changeNavIndex, e.id)
      this.coin_price = Number(e.coin_price).toFixed(6)
      this.change_num = e.change_num
    },
    fomatFloat(src, pos) { //保留两位小数
      return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
    },
    getTxData() {
      get24Hr().then(res => {
        if (res.code == 1) {
          this.txData = res.data
        }
      })

    },
    getPopList() {
      getIconPriceList().then(res => {
        if (res.code == 1) {
          this.popList = res.data
          if (res.data.length > 0) {
            if(this.id === 0){
              this.name = res.data[0].coin_name + '/USDT'
              this.id = res.data[0].id
              this.change_num = res.data[0].change_num
              this.coin_price = Number(res.data[0].coin_price).toFixed(6)
            }else{
              res.data.forEach((v)=>{
                if(this.id === v.id){
                  this.name = v.coin_name + '/USDT'
                  this.id = v.id
                  this.change_num = v.change_num
                  this.coin_price = Number(v.coin_price).toFixed(6)
                }
              })
            }
            sessionStorage.setItem('ids', this.id)
            this.getKline('1m', this.id)
          }
        }
      })
    },
    // 获取k线数据,生成k线
    timeformat(datastr) {
      let dt = new Date(datastr);
      let y = dt.getFullYear();
      let m = (dt.getMonth() + 1).toString().padStart(2, "0");
      let d = dt.getDate().toString().padStart(2, "0");
      let h = dt.getHours().toString().padStart(2, "0");
      let f = dt.getMinutes().toString().padStart(2, "0");
      let k = dt.getSeconds().toString().padStart(2, "0");
      return `${y}-${m}-${d} ${h}:${f}`;
    },
    calculateMA(dayCount, data) {
      let result = [];
      for (let i = 0, len = data.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        let sum = 0;
        for (let j = 0; j < dayCount; j++) {
          sum += data[i - j][1];
        }
        // console.log(sum, dayCount)
        result.push((sum / dayCount).toFixed(2));
      }
      return result;
    },
    getKline(id, dealId) {
      getKlines({
        interval: id,
        dealId: dealId
      }).then(res => {
        if (res.code == 1) {
          let rawData = res.data;
          let dates = rawData.map((item) => {
            return this.timeformat(item[0]);
          })
          //开、收、低、高
          let data = rawData.map(function (item) {
            return [+item[1], +item[4], +item[3], +item[2], +item[5]];
          })
          let volumes = rawData.map(function (item, index) {
            return [index, item[5], item[1] > item[4] ? 1 : -1];
          })
          let dataMA5 = this.calculateMA(5, data);
          let dataMA10 = this.calculateMA(10, data);
          let dataMA30 = this.calculateMA(30, data);
          let volumeMA5 = this.calculateMA(5, volumes);
          let volumeMA10 = this.calculateMA(10, volumes);
          myChart.setOption({
            xAxis: [{
              data: dates
            },
              {
                data: dates
              },
            ],
            series: [{
              name: '日K',
              data: data
            },
              {
                name: 'MA5',
                data: dataMA5
              },
              {
                name: 'MA10',
                data: dataMA10
              },
              {
                name: 'MA30',
                data: dataMA30
              },
              {
                name: 'Volume',
                data: volumes
              },
              {
                name: 'VolumeMA5',
                data: volumeMA5
              },
              {
                name: 'VolumeMA10',
                data: volumeMA10
              },
            ]
          })
        }
      })


    },
    // 绘制(配置项)
    draw() {
      let that = this;
      let upColor = '#03ad91';
      let downColor = '#dd345b';
      let colorList = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a',
        '#6e7074',
        '#546570', '#c4ccd3'
      ];
      let labelFont = 'bold 12px Sans-serif';
      let option = {
        backgroundColor: '#171E28',
        title: {
          show: false
        },
        legend: {
          show: false
        },
        visualMap: {
          show: false,
          seriesIndex: 4,
          dimension: 2,
          pieces: [{
            value: 1,
            color: downColor
          }, {
            value: -1,
            color: upColor
          }]
        },
        grid: [{
          top: '5%',
          left: 20,
          right: 20,
          height: '70%'
        },
          {
            top: '80%',
            left: 20,
            right: 20,
            height: '16%'
          },
        ],
        axisPointer: { //坐标轴指示器配置项
          link: {
            xAxisIndex: 'all'
          },
          label: {
            backgroundColor: '#0d1723',
            color: '#fff',
            borderColor: 'rgb(99, 117, 139)',
            borderWidth: 1,
            borderRadius: 2,
            fontSize: 10
          }
        },
        xAxis: [{
          type: 'category', //坐标轴类型。(value:数值轴，适用于连续数据。,category:类目轴，适用于离散的类目数据,time: 时间轴，适用于连续的时序数据,log:对数轴。适用于对数数据)
          data: [], //类目数据，在类目轴（type: 'category'）中有效。
          scale: true,
          boundaryGap: false, //坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样。
          axisLine: {
            show: false
          }, //坐标轴轴线相关设置
          axisTick: {
            show: false
          }, //坐标轴刻度相关设置。
          axisLabel: {
            show: false,
          }, //坐标轴刻度标签的相关设置。
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255, 0.1)'
            }
          }, //坐标轴在 grid 区域中的分隔线。
          min: 'dataMin', //坐标轴刻度最小值。可以设置成特殊值 'dataMin'，此时取数据在该轴上的最小值作为最小刻度。
          max: 'dataMax', //坐标轴刻度最大值。可以设置成特殊值 'dataMax'，此时取数据在该轴上的最大值作为最大刻度。
          axisPointer: {
            label: {
              margin: 200
            }
          },
        }, {
          type: 'category',
          gridIndex: 1, //x 轴所在的 grid 的索引，默认位于第一个 grid。
          data: [], //类目数据，在类目轴（type: 'category'）中有效。
          scale: true,
          boundaryGap: false, //坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样。
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,1)',
              width: 1
            }
          }, //坐标轴轴线相关设置
          axisTick: {
            show: false
          }, //坐标轴刻度相关设置。
          axisLabel: { //坐标轴刻度标签的相关设置。
            show: true,
            margin: 6,
            fontSize: 10,
            color: 'rgba(99, 117, 139, 1.0)',
            formatter: function (value) {
              return echarts.format.formatTime('MM-dd', value);
            }
          },
          splitNumber: 20,
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255, 0.1)'
            }
          }, //坐标轴在 grid 区域中的分隔线。
          min: 'dataMin', //坐标轴刻度最小值。可以设置成特殊值 'dataMin'，此时取数据在该轴上的最小值作为最小刻度。
          max: 'dataMax', //坐标轴刻度最大值。可以设置成特殊值 'dataMax'，此时取数据在该轴上的最大值作为最大刻度。
          // axisPointer: { show: true, type: 'none', label: { show: false }},
        }],
        yAxis: [{
          type: 'value', //坐标轴类型。(value:数值轴，适用于连续数据。,category:类目轴，适用于离散的类目数据,time: 时间轴，适用于连续的时序数据,log:对数轴。适用于对数数据)
          position: 'right', //y 轴的位置。'left','right'
          scale: true, //是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度。在双数值轴的散点图中比较有用。(在设置 min 和 max 之后该配置项无效。)
          axisLine: {
            show: true
          }, //坐标轴轴线相关设置。
          axisTick: {
            show: true,
            inside: true
          }, //坐标轴刻度相关设置。
          axisLabel: { //坐标轴刻度标签的相关设置。
            show: true,
            color: 'rgba(99, 117, 139, 1.0)',
            inside: true,
            fontSize: 10,
            formatter: function (value) {
              return Number(value).toFixed(2)
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255, 0.1)'
            }
          }, //坐标轴在 grid 区域中的分隔线。
        }, {
          type: 'value',
          position: 'right',
          scale: true,
          gridIndex: 1,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        }],

        animation: false, //是否开启动画。
        color: colorList,
        tooltip: {
          show: true, //是否显示提示框组件，包括提示框浮层和 axisPointer。
          trigger: 'axis', //触发类型。item,axis,none
          formatter(params) {
            let tooltip = '';
            let time = '',
              open = 0,
              high = 0,
              low = 0,
              close = 0,
              amount = 0;
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName === '日K') {
                time = params[i].name;
                open = params[i].data.length > 1 ? Number(that.formatterNum(params[i].data[1], 2)) : 0;
                close = params[i].data.length > 1 ? Number(that.formatterNum(params[i].data[2], 2)) : 0;
                low = params[i].data.length > 1 ? Number(that.formatterNum(params[i].data[3], 2)) : 0;
                high = params[i].data.length > 1 ? Number(that.formatterNum(params[i].data[4], 2)) : 0;
                amount = params[i].data.length > 1 ? Number(that.formatterNum(params[i].data[5], 2)) : 0;
                // console.log(time,open,close,low,high,amount)
                tooltip = '<div class="charts-tooltip">' +
                  '<div class="charts-tooltip-row"><div class="ctr-label">' + '时间' +
                  '</div><div class="ctr-value">' + time + '</div></div>' +
                  '<div class="charts-tooltip-row"><div class="ctr-label">' + '开' +
                  '</div><div class="ctr-value">' + open + '</div></div>' +
                  '<div class="charts-tooltip-row"><div class="ctr-label">' + '高' +
                  '</div><div class="ctr-value">' + high + '</div></div>' +
                  '<div class="charts-tooltip-row"><div class="ctr-label">' + '低' +
                  '</div><div class="ctr-value">' + low + '</div></div>' +
                  '<div class="charts-tooltip-row"><div class="ctr-label">' + '收' +
                  '</div><div class="ctr-value">' + close + '</div></div>' +
                  '<div class="charts-tooltip-row"><div class="ctr-label">' + '数量' +
                  '</div><div class="ctr-value">' + amount + '</div></div></div>';
              }
              if (params[i].seriesName === 'MA5') {
                that.MA5 = params[i].data !== 'NAN' ? Number(that.formatterNum(params[i].data, 2)) : 0
              }
              if (params[i].seriesName === 'MA10') {
                that.MA10 = params[i].data !== 'NAN' ? Number(that.formatterNum(params[i].data, 2)) : 0
              }
              if (params[i].seriesName === 'MA30') {
                that.MA30 = params[i].data !== 'NAN' ? Number(that.formatterNum(params[i].data, 2)) : 0
              }
              if (params[i].seriesName === 'VolumeMA5') {
                that.volMA5 = params[i].data !== 'NAN' ? Number(that.formatterNum(params[i].data, 2)) : 0
              }
              if (params[i].seriesName === 'VolumeMA10') {
                that.volMA10 = params[i].data !== 'NAN' ? Number(that.formatterNum(params[i].data, 2)) : 0
              }
            }
            return tooltip;
          },
          triggerOn: 'click', //提示框触发的条件 'mousemove','click','mousemove|click','none'
          textStyle: {
            fontSize: 10
          }, //提示框浮层的文本样式
          backgroundColor: 'rgba(30,42,66,0.8);', //提示框浮层的背景颜色。
          borderColor: '#2f3a56', //提示框浮层的边框颜色。
          borderWidth: 2,
          position: function (pos, params, el, elRect, size) { //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置。
            let obj = {
              top: 20
            };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 10;
            return obj;
          },
          axisPointer: { //坐标轴指示器配置项。
            label: {
              color: 'rgba(255,255,255,.87)',
              fontSize: 9,
              backgroundColor: '#020204',
              borderColor: "#9c9fa4",
              shadowBlur: 0,
              borderWidth: 0.5,
              padding: [4, 2, 3, 2],
            },
            animation: false,
            type: 'cross',
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(30, 42, 66, 0.1)' // 0% 处的颜色
                }, {
                  offset: 0.7,
                  color: 'rgba(30, 42, 66,0.9)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(30, 42, 66,0.2)' // 100% 处的颜色
                }]
              },
              width: 10,
              shadowColor: 'rgba(30, 42, 66,0.7)',
              shadowBlur: 0,
              shadowOffsetY: 68,
            }
          }
        },

        dataZoom: [{ //用于区域缩放
          type: 'inside',
          xAxisIndex: [0, 1],
          realtime: false,
          start: 50,
          end: 100,
        }],
        series: [{
          type: 'candlestick',
          name: '日K',
          data: [],
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upColor,
            borderColor0: downColor
          },
          markPoint: {
            symbol: 'rect',
            symbolSize: [-10, 0.5],
            symbolOffset: [5, 0],
            itemStyle: {
              color: 'rgba(255,255,255,.87)'
            },
            label: {
              color: 'rgba(255,255,255,.87)',
              offset: [10, 0],
              fontSize: 10,
              align: 'left',
              formatter: function (params) {
                return Number(params.value).toFixed(2)
              }
            },
            data: [{
              name: 'max',
              type: 'max',
              valueDim: 'highest'
            },
              {
                name: 'min',
                type: 'min',
                valueDim: 'lowest'
              }
            ]
          },
        },
          {
            name: 'MA5',
            type: 'line',
            data: [],
            symbol: 'none', //去除圆点
            smooth: true,
            lineStyle: {
              normal: {
                opacity: 1,
                width: 1,
                color: "#eef4ba"
              }
            },
            z: 5
          },
          {
            name: 'MA10',
            type: 'line',
            data: [],
            symbol: 'none', //去除圆点
            smooth: true,
            lineStyle: {
              normal: {
                opacity: 1,
                width: 1,
                color: '#83c1c5'
              }
            },
            z: 4
          },
          {
            name: 'MA30',
            type: 'line',
            data: [],
            symbol: 'none', //去除圆点
            smooth: true,
            lineStyle: {
              normal: {
                opacity: 1,
                width: 1,
                color: '#b39cd8'
              }
            },
            z: 3
          },
          {
            name: 'Volume',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: []
          },
          {
            name: 'VolumeMA5',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: [],
            symbol: 'none', //去除圆点
            smooth: true,
            lineStyle: {
              normal: {
                opacity: 1,
                width: 1,
                color: "#eef4ba"
              }
            },
            z: 5
          },
          {
            name: 'VolumeMA10',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: [],
            symbol: 'none', //去除圆点
            smooth: true,
            lineStyle: {
              normal: {
                opacity: 1,
                width: 1,
                color: '#83c1c5'
              }
            },
            z: 4
          },
        ]
      };
      myChart.setOption(option);
      // 加载上一页数据
      myChart.on('datazoom', function (params) {
        let num = params.batch[0]['start'];
        if (num == 0) {
          console.log('到最左边了')
        }
      })
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.charts {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
}


::v-deep .charts-tooltip-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  padding: 5px 0;
}

.ctr-label {
  color: #51617b;
}
.echarts-line {
  width: 100%;
  height: 4px;
  background: #1f282f;
  margin-bottom: 10px;
}

.box-main-changeNav {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}
.box-main-changeNav div {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 40px;
}

.changeNav-active {
  color: #FFC300 !important;
}
.box-main-select {
  margin-right: 6px !important;
  border-radius: 4px;
  width: 120px;
  font-size: 16px;

}

::v-deep .el-input__inner {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #FFFFFF;
  line-height: 0;
}

::v-deep .el-select {
  width: 100%;
}

.box-main-head {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;

}

.box-main-nav {
  margin-right: 45px;
}

.whte {
  color: #FFFFFF;
}

.box-main-line {
  width: 100%;
  height: 1px;
  background: #29343D;
  margin-bottom: 10px;
}
.quotation-active {
  border-bottom: 1px solid #FFC300;
  color: #FFC300 !important;
}
</style>
