<template>
  <div class="footer">
    <div class="quotation-box">
      <!-- nav -->
      <div class="quotation-box-head">
        <div class="display-flex align-items-center">
          <div v-for="(item,i) in nav" :key="i"
          class="quotation-box-nav"
          :class="[footerIndex == item.id ? 'quotation-active' : '']"
          @click="footerBtn(item.id)">
            <div>{{$t(item.name)}}</div>
          </div>
        </div>
      </div>
      <!-- list -->
      <div>
        <!-- ul -->
        <div class="quotation-list" v-if="viewName==='transaction'">
          <div class="quotation-list-tit" v-if="footerIndex!==3">
            <div v-for="(item,i) in ulTitle" :key="i"
            :class="{
              'text-align-left':item.id === 1,
              'width0185':item.id === 2,
              'width0065':item.id === 4,
              'text-align-center':(item.id === 3||item.id === 4||item.id === 5||item.id === 6),
              'text-align-right':(item.id === 7||item.id === 8||item.id === 9),
            }">
              {{$t(item.name)}}
            </div>
          </div>
          <div class="quotation-list-tit" v-else>
            <div v-for="(item,i) in ulTitle" :key="i"
            class="width025"
            :class="i === 0?'text-align-left':'text-align-center'">
              {{$t(item.name)}}
            </div>
          </div>
        </div>
        <div class="quotation-list" v-else>
          <div class="quotation-list-tit" v-if="footerIndex===1">
            <div v-for="(item,i) in ulTitle" :key="i"
            :class="{
              'text-align-left':(item.id === 1||item.id === 2),
              'width0065':item.id === 3,
              'text-align-center':(item.id === 3||item.id === 4),
              'text-align-right':(item.id===5||item.id===6||item.id === 7||item.id === 8),
            }">
              {{$t(item.name)}}
            </div>
          </div>
          <div class="quotation-list-tit" v-else>
            <div v-for="(item,i) in ulTitle" :key="i"
            :class="{
              'text-align-left':(item.id === 1||item.id === 2),
              'width0065':item.id === 3,
              'text-align-center':(item.id === 3||item.id === 4||item.id===6),
              'width0185':item.id === 5,
              'text-align-right':(item.id === 7||item.id === 8||item.id===9),
            }">
              {{$t(item.name)}}
            </div>
          </div>
        </div>

        <div v-if="list.length === 0">
          <NoData></NoData>
        </div>
        <div v-else>
          <div v-if="viewName==='transaction'">
            <div v-if="footerIndex!==3">
              <div class="quotation-list" v-for="(item, index) in list" :key="index">
                <div class="quotation-list-tit">
                  <div class="text-align-left"><span class="c-fff">{{item.coin_name}}</span> /USDT</div>
                  <div class="width0185">{{item.created_at}}</div>
                  <div class="text-align-center">{{item.status_str}}</div>
                  <div :class="item.type === 1?'green':'red'"
                    class="text-align-center width0065">
                    {{ item.type === 1?$t('transaction.mr'):$t('transaction.mc')}}
                  </div>
                  <div class="text-align-center">{{item.bid_price.toFixed(item.bid_price>1000?2:6)}} USDT</div>
                  <div class="text-align-center">{{item.bid_num.toFixed(item.bid_num>1000?2:6)}} {{item.coin_name}}</div>

                  <div class="text-align-right" >{{makeAmount(item)}} {{item.coin_name}}</div>
                  <div class="text-align-right">{{setTransactionAmount(item.bid_num,item.bid_price)}} USDT</div>
                  <div class="revoke-felx" @click="cancel(item)" v-if="footerIndex === 1">
                    <span>{{ $t('transaction.cx') }}</span>
                    <img class="revoke-img" src="@/assets/market/jtl.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="quotation-list" v-for="(item, index) in list" :key="index">
                <div class="quotation-list-tit">
                  <div class="text-align-left width025">{{item.coin_name}}</div>
                  <div class="text-align-center width025">{{setTotalAssets(item.lock_num,item.num)}}</div>
                  <div class="text-align-center width025">{{item.num.toFixed(item.num>1000?2:6)}}</div>
                  <div class="text-align-center width025">{{item.lock_num.toFixed(item.lock_num>1000?2:6)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="footerIndex === 1">
              <div class="quotation-list" v-for="(item, index) in list" :key="index">
                <div class="quotation-list-tit">
                  <div class="text-align-left"><span style="color: #FFF;">{{item.coin_name}}</span> /USDT</div>
                  <div class="text-align-left">{{item.status_str}}</div>
                  <div :class="item.type === 1?'green':'red'"
                    class="text-align-center width0065">
                    {{ item.type === 1?$t('transaction.mz'):$t('transaction.md')}}
                  </div>
                  <div class="text-align-center">{{item.money.toFixed(item.money>1000?2:6)}} USDT</div>
                  <div class="text-align-right">{{item.created_at}}</div>
                  <div class="text-align-right">{{item.coin_price.toFixed(item.coin_price>1000?2:6)}} USDT</div>
                  <div class="text-align-right">{{item.end_at}}</div>
                  <div class="text-align-right">{{item.profit}}%</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="quotation-list" v-for="(item, index) in list" :key="index">
                <div class="quotation-list-tit">
                  <div class="text-align-left"><span class="c-fff">{{item.coin_name}}</span> /USDT</div>
                  <div class="text-align-left">{{item.status_str}}</div>
                  <div :class="item.type === 1?'green':'red'"
                    class="text-align-center width0065">
                    {{ item.type === 1?$t('transaction.mz'):$t('transaction.md')}}
                  </div>
                  <div class="text-align-center">{{item.money.toFixed(item.money>1000?2:6)}} USDT</div>
                  <div class="width0185">{{item.created_at}}</div>
                  <div class="text-align-center">{{item.coin_price.toFixed(item.coin_price>1000?2:6)}} USDT</div>
                  <div class="text-align-right">{{item.end_at}}</div>
                  <div class="text-align-right">{{item.end_price.toFixed(item.end_price>1000?2:6)}} USDT</div>
                  <div class="text-align-right green">+{{item.profit_money.toFixed(item.profit_money>1000?2:6)}} USDT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- x -->
      </div>
    </div>
    <el-pagination v-if="!(viewName==='transaction'&&footerIndex === 2)"
        class="selectPage" hide-on-single-page  prev-text="←" next-text="→"
        @current-change="handleCurrentChange"
        :current-page="page" layout="prev, pager, next" :total="total">
    </el-pagination>
  </div>
</template>

<script>
  import {getDealList,cancelDeal} from '@/api/deal.js';
  import {getCoinList} from '@/api/money.js';
  import {getFutureOrderList} from "@/api/future.js";
  import NoData from '@/components/noData/noData.vue';
  export default {
    data(){
      return{
        footerIndex:1,
        nav:[],
        ulTitle:[],
        viewName:'',
        page:1,
        total:0,
        list:[],
        isAJAX:false,
      }
    },
    components:{NoData,},
    mounted() {},
    methods:{
      makeAmount(item){
        let num = 0
        if(item.type!==2){
          num =item.make_num
        }else {
          if(item.usdt_num>0)num = (item.make_num / item.usdt_num)*item.bid_num
        }
        if(num >= 1000){
          return num.toFixed(2)
        }else{
          return num.toFixed(6)
        }
      },
      setTransactionAmount(bid_num,bid_price){
        let num = 0
        num = bid_num * bid_price
        if(num >= 1000){
          return num.toFixed(2)
        }else{
          return num.toFixed(6)
        }
      },
      setTotalAssets(lock_num,usable_num){
        let num = 0
        num = lock_num + usable_num
        if(num >= 1000){
          return num.toFixed(2)
        }else{
          return num.toFixed(6)
        }
      },

      getlist(viewName){
          if(viewName === 'transaction'){
            if(this.footerIndex!=3){
              let fromData = {
                status:this.footerIndex,
                page:this.page,
              }
              getDealList(fromData).then((res)=>{
                if(res.code === 1){
                  this.total = res.data.total
                  this.list = res.data.items
                }
              })
            }else{
              getCoinList().then((res)=>{
                if(res.code === 1){
                  this.list = res.data.items
                }
              })
            }

          }else{
            let fromData = {
              status:this.footerIndex,
              page:this.page,
            }
            getFutureOrderList(fromData).then((res)=>{
              this.total = res.data.total
              this.list = res.data.items
            })
          }
      },

      footerBtn(type){
        if(this.footerIndex !== type){
          this.page = 1;
          this.footerIndex = type;
          this.setNavOrUltitle(this.viewName,this.footerIndex,this.page);
        }
      },

      setNavOrUltitle(viewName,type,page){
        // viewName:页面类型 type:nav类型
        this.viewName = viewName;
        if(page){
          this.page = page
        }
        if(viewName === 'transaction'){
          // BB交易
          this.nav = [
            {name:'transaction.dqwt',id:1},
            {name:'transaction.lswt',id:2},
            {name:'transaction.zcgl',id:3},
          ];
          if(type === 1 || type === 2){
            // BB 当前委托1 历史委托2
            this.ulTitle = [
              {name:'transaction.jyd',id:1},
              {name:'transaction.sj',id:2},
              {name:'assets.zt',id:3},
              {name:'transaction.fx',id:4},
              {name:'transaction.jg',id:5},
              {name:'transaction.ddsl',id:6},
              {name:'transaction.ycjsl',id:7},
              {name:'transaction.je',id:8},
            ];
            if(type === 1){
              this.ulTitle.push({name:this.$t('transaction.cz'),id:9})
            }
          }else{
            // BB 资产管理3
            this.ulTitle = [
              {name:'transaction.bz',id:1},
              {name:'transaction.ze',id:2},
              {name:'transaction.kyye',id:3},
              {name:'transaction.djse',id:4},
            ];
          }
        }else{
           // 期货交易
           this.nav = [
             {name:'transaction.dqwt',id:1},
             {name:'transaction.lswt',id:2},
           ];
           if(type === 1){
             this.ulTitle = [
               {name:'transaction.jyd',id:1},
               {name:'assets.zt',id:2},
               {name:'transaction.fx',id:3},
               {name:'transaction.ddsl',id:4},
               {name:'transaction.kssj',id:5},
               {name:'transaction.ksjg',id:6},
               {name:'transaction.jssj',id:7},
               {name:'transaction.yjyl',id:8},
             ];
           }else{
              this.ulTitle = [
                {name:'transaction.jyd',id:1},
                {name:'assets.zt',id:2},
                {name:'transaction.fx',id:3},
                {name:'transaction.mrsl',id:4},
                {name:'transaction.kssj',id:5},
                {name:'transaction.ksjg',id:6},
                {name:'transaction.jssj',id:7},
                {name:'transaction.jsjg',id:8},
                {name:'transaction.bdyl',id:9},
              ];
           }
        }
        this.getlist(viewName);
      },
      handleCurrentChange(e){
        this.page = e;
        this.getlist(this.viewName);
      },
      cancel(item){
        if(this.isAJAX){
          return
        }
        this.isAJAX = true
        cancelDeal({dealId:item.id}).then((res)=>{
          if(res.code === 1){
            this.isAJAX = false
            this.$message({
              message: this.$t('common_msg.success'),
              type: 'success'
            });
            this.page = 1
            this.setNavOrUltitle(this.viewName,this.footerIndex,this.page);
            this.$emit('cancel',true);
          }else{
            this.isAJAX = false
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .footer {
    width: 100%;
    background: #171E28;
    margin-bottom: 20px;

    .selectPage {
      margin: 20px 40px 0 0;
      text-align: right;
      ::v-deep .btn-prev{
        color: #fff;
        background:none;
      }
      ::v-deep .el-pager{
        color: #fff;
        background: none;
      }
      ::v-deep .el-pager li{
        background: none;
      }
      ::v-deep .btn-next{
        color: #fff;
        background:none;
      }
    }

    .display-flex{
      display: flex;
    }
    .align-items-center{
      align-items: center;
    }
    .green {
      color: #43CF7C;
    }
    .red {
      color: #FF6B65;
    }
    .c-fff{
      color: #fff;
    }
    .text-align-left{
      text-align: left;
    }
    .text-align-center{
      text-align: center;
    }
    .text-align-right{
      text-align: right;
    }
    .width0065{
      width: 6.5%
    }
    .width0185{
      width:18.5%
    }
    .width025{
      width: 25%
    }


    .quotation-box{
      .quotation-box-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #1F282F;
        cursor: pointer;
        padding: 10px 40px 0 40px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .quotation-box-nav {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          color: rgba(255, 255, 255, 0.6);
          margin-right: 32px;
        }
        .quotation-active{
          color: #fff;
        }
      }
      .quotation-list {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        padding-bottom: 10px;
        margin-bottom: 16px;
        .quotation-list-tit {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0 40px;
          text-align: center;
          div{
            width: 12.5%;
            font-size: 12px;
          }
          .revoke-felx {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 12px;
            text-decoration-line: underline;
            color: rgba(255, 107, 101, 1);
            cursor: pointer;
            .revoke-img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
</style>
